import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Header from './components/Header';
import './ABC.scss';
// import Start from './components/Start';
// import Clients from './components/Clients';
import Features from './components/Features';
import Enterprise from '../../components/Enterprise';
import Form from '../../components/Form';

class ABC extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { content, data, type, intl } = this.props;
    return (
      <section className="abc">
        <Header data={content.header}/>
        <Features features={content.features}/>
        <Enterprise enterprise={content.enterprise}/>
        {/* <Clients clients={content.clients}/>*/}
        <Form form={content.form} intl={intl}/>
        {/* <Start /> */}
      </section>
    );
  }
}

ABC.propTypes = {
  content: PropTypes.object,
};

export default ABC;

