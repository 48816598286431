import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {Button} from 'mw-style-react';
import './Header.scss';
import { Link } from '../../../../../../i18n';
import Utils from '../../../../../../utils/utils';
import Image from '../../../../../../components/Image';

class Header extends PureComponent {
  render() {
    const { data, intl } = this.props;

    return (
      <div className="abc__header__wrapper">
        <div className="headerPage">
          <div className="col-5">
            <div className="abc__header__image">
              <Image className="abc__header__image__container" file={'abc.svg'}/>
            </div>
          </div>
          <div className="col-7 abc__header__header__text">
            <div className="abc__header__title">
              <h1>
                {intl.formatMessage({id: data.title})}
              </h1>
              {/* <div className="abc__header__title__beta">Beta</div> */}
            </div>
            <div className="abc__header__subTitle">
              <span
                dangerouslySetInnerHTML={{
                  __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.subTitle}))
                }}
              />
            </div>
            <div className="abc__header__text">
              <span
                dangerouslySetInnerHTML={{
                  __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.text}))
                }}
              />
            </div>
            <div className="abc__header__buttons">
              <Link
                to={data.button.link}
                className="abc__header__btn__container"
                event={data.button.event}
              >
                <Button
                  className={'button__default abc__header__btn'}
                  label={intl.formatMessage({id: data.button.text})}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


Header.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(Header);

