import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './Features.scss';
import Image from '../../../../../../components/Image/Image';

class Features extends PureComponent {
  render() {
    const { features, intl } = this.props;

    return (
      <div className="abc__features__container">
        <div className="abc__features">
          <div className="abc__features__title">
            {intl.formatMessage({id: features.title})}
          </div>
          <div className="abc__features__list">
            {features.list.map(item => (
              <div className="abc__features__item">
                <div className="abc__features__item__title">
                  {intl.formatMessage({id: item.title})}
                </div>
                <div className="abc__features__item__subTitle">
                  {intl.formatMessage({id: item.text})}
                </div>
                <div className="abc__features__item__phone">
                  <Image file={item.image}/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Features.propTypes = {
  features: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(Features);

