import React from 'react';
import PropTypes from 'prop-types';
import ABC from '../../templates/BotPlatform/pages/ABC';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';

const appleBusinessChat = {
  header: {
    title: 'abcTitle',
    subTitle: 'abcSubTitle',
    text: 'abcText',
    button: {
      text: 'abcButtonText',
      link: '/form/live-demo'
    }
  },
  features: {
    title: 'abcFeaturesTitle',
    list: [
      {title: 'abcFeaturesBuiltInMessagesTitle', text: 'abcFeaturesBuiltInMessagesText', image: 'abc-builtInMessages.png'},
      {title: 'abcFeaturesPayTitle', text: 'abcFeaturesPayText', image: 'abc-pay.png'},
      {title: 'abcFeaturesOperatorTitle', text: 'abcFeaturesOperatorText', image: 'abc-operator.png'},
      {title: 'abcFeaturesMediaTitle', text: 'abcFeaturesMediaText', image: 'abc-media.png'},
      {title: 'abcFeaturesAppointmentsTitle', text: 'abcFeaturesAppointmentsText', image: 'abc-appointments.png'},
      {title: 'abcFeaturesExtensionCallTitle', text: 'abcFeaturesExtensionCallText', image: 'abc-extensionCall.png'},
    ]
  },
  enterprise: {
    title: 'botPlatformEnterpriseTitle',
    list: [
      {
        key: 'security',
        title: 'botPlatformEnterpriseSecurityTitle',
        subTitle: 'botPlatformEnterpriseSecuritySubTitle',
        image: 'security.png',
      },
      {
        key: 'performance',
        title: 'botPlatformEnterprisePerformanceTitle',
        subTitle: 'botPlatformEnterprisePerformanceSubTitle',
        image: 'performance.png',
      },
      {
        key: 'scale',
        title: 'botPlatformEnterpriseScaleTitle',
        subTitle: 'botPlatformEnterpriseScaleSubTitle',
        image: 'scale.png',
      }
    ]
  },
  clients: {
    title: 'abcClientsTitle',
    list: [
      '',
    ]
  },
  // start: {
    // title: 'botPlatformStartTitle',
    // list: [
    //   {text: 'botPlatformSignIn', link: host},
    //   {text: 'botPlatformTutorial', link: 'https://doc.corezoid.com/en/bot-platform/v2/'},
    //   {text: 'botPlatformStartBots', link: ''},
    // ]

  // },
  form: {
    type: 'ABC',
    text: 'abcFormTitle',
    phoneLeft: 'abc-phon-left.png',
    phoneRight: 'abc-phon-right.png',
  },
};

const IndexPage = (props) => (
  <Layout page={'bot-platform'} {...props}>
    <ABC
      content={appleBusinessChat}
      data={props.data}
      {...props}
    />
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object
};

export default withIntl(IndexPage);
